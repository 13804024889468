import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import * as DesignSystem from "./Design-system"
import { ThemeContext } from "../tokens/theme"
import * as tokens from "../tokens/tokens";


const $Project = styled.div`
    margin: ${tokens.space.xxxl} 0;

`

const $Title = styled.h3`
    font-family: ${tokens.typography.fontFamily.heading};
    text-rendering: optimizeLegibility;
    font-size: ${tokens.typography.fontSize.lg};
    line-height: ${tokens.typography.lineHeight.sm};
    letter-spacing: ${tokens.typography.letterSpacing.body};
    color: ${props => props.theme.colorTextPrimary};
    padding-top: ${tokens.space.xxl};
    margin-bottom: ${tokens.space.lg};
    
    & > span {
        color: ${props => props.theme.colorTextSecondary};
    }
    
    & span > span{
        color: ${props => props.theme.colorTextPrimary};
    }
    
    @media (min-width: 640px) {
        letter-spacing: ${tokens.typography.letterSpacing.display};
        font-size: ${tokens.typography.fontSize.xl};
        line-height: ${tokens.typography.lineHeight.xs};
    }   
`

const $Stack = styled.div`
    
    display: block;
    width: 100%;
    margin: ${tokens.space.sm} 0;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
	overflow-x: scroll;

	scroll-snap-type: x mandatory;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */


    &::-webkit-scrollbar {
        display: none;
    }
    
    & > .gatsby-image-wrapper {
        display: inline-block;
        scroll-snap-align: center;
        margin-right: ${tokens.space.md};
        width: 90%; 
        border-radius: 6px;
        /* max-height: 60vh; */
    }
    
    & > .gatsby-image-wrapper:only-child{
        width: 100%;
        margin-right: 0px;
    }

    .gatsby-image-wrapper picture > img{
        object-position: top center !important;
    }

`

const $Button = styled.a`
    display: inline-block;
    margin-top: ${tokens.space.md};
    text-decoration: none;
    padding: ${tokens.space.sm} ${tokens.space.md};
    border: 2px solid ${props => props.theme.colorSurface};
    background: ${props => props.theme.colorSurface};
    font-size: ${tokens.typography.fontSize.xs};
    border-radius: ${tokens.radius.sm};

    &:hover{
        background: ${props => props.theme.colorBackground};
        border: 2px solid ${props => props.theme.colorSurface};
        padding: ${tokens.space.sm} ${tokens.space.md};
        margin-left: 0;
    }
`

const Project = (props) => {
    
    // console.log('Number of images: ' + props.images.length);

    return(
        <ThemeContext.Consumer>
        { context=>(

        <$Project> 
            <$Title theme={context.colorTheme}><span dangerouslySetInnerHTML={{ __html: props.title }} /></$Title>

            <$Stack>
                {props.images.map( ( {childImageSharp}, index ) => (
                    <Img fluid={childImageSharp.fluid} />
                ))}
            </$Stack>

            <DesignSystem.Meta theme={context.colorTheme}>{props.meta}</DesignSystem.Meta>
            <DesignSystem.Body>{props.description}</DesignSystem.Body>
           
            {props.casestudy &&
                <Link to={props.slug}><$Button>View case study</$Button></Link>
            }
            
            {props.link && 
              <$Button theme={context.colorTheme} target="_blank" href={props.link}>Have a look</$Button>
            }
           
            
        </$Project>

        )}
        </ThemeContext.Consumer>
    );
}

export default Project;
