import React, { useState, useEffect } from "react"
import styled, { keyframes } from 'styled-components'
import { ThemeContext } from "../tokens/theme"
import useDarkMode from 'use-dark-mode';
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import * as tokens from "../tokens/tokens"

import Layout from "../components/Layout"
import Audio from "../components/Audio"
import SEO from "../components/SEO"
import Project from "../components/Project"

import * as DesignSystem from "../components/Design-system"
import Portrait from "../components/Portrait";

const heroAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(16px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`

const loadAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`

const $Intro = styled.div`

  margin-bottom: ${tokens.space.xxxl};
  margin-top: ${tokens.space.xxl};
  
  @media (min-width: 640px) {
    margin-top: ${tokens.space.xxxl};
  }

  animation-name: ${heroAnimation};
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`

const $Content = styled.div`
  opacity: 0;
  animation-name: ${loadAnimation};
  animation-duration: 3s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`

const IndexPage = ( {data} ) => {

  return(

        <Layout>

          <SEO title="Work" />

          <$Intro>

            <DesignSystem.Hero>
              <span><Audio>Filip Pižl</Audio> is a digital product designer & manager based in Dresden, Germany.</span> He is interested in design systems, appreciates fine typography and is passionate about espresso. Currently working at <span>Staffbase</span>, the leading employee communications platform.
            </DesignSystem.Hero>

            <p></p>

            {/* <DesignSystem.Lead>
              <Audio>Filip Pižl</Audio> is a digital product designer based in Dresden, Germany. 
            </DesignSystem.Lead> */}

          </$Intro>

          <$Content>

            <DesignSystem.Hr />
            <DesignSystem.H2>
              Selected Work
            </DesignSystem.H2>

              {data.projects.nodes.map( ( {frontmatter} ) => 

              (<Project 
                title={frontmatter.title}
                meta={frontmatter.meta}
                description={frontmatter.description}
                slug={frontmatter.slug}
                images={frontmatter.image}
                casestudy={frontmatter.casestudy}
                link={frontmatter.link}
                > 
                {/* {frontmatter.image.map( ( {childImageSharp} ) => (
                  <Img fluid={childImageSharp.fluid} />
                ) )} */}
              </Project>)

              )}  
          
          </$Content>

          
        </Layout>
        
  );
}

export const query = graphql`

query IndexQuery {
  projects: allMdx(filter: {fileAbsolutePath: {regex: "/pages/work/"}}, sort: {fields: frontmatter___index, order: DESC}) {
      nodes {
        frontmatter {
          slug
          title
          meta
          index
          description
          casestudy
          link
          image{
              childImageSharp {
                  fluid(maxWidth: 768, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  `

export default IndexPage
